<template>
  <div v-if="pageReady" class="p-5 m-5 shadow bg-white">
    <!-- user profile details section -->
    <div id="profile_info_section" class="container p-4">
      <div class="d-flex align-items-center justify-content-between">
        <h2 class="my-5 fs-3 text-dark">User Profile</h2>
        <div>
          <button
            type="button"
            class="btn bg-primary p-2"
            @click="editMode = !editMode"
          >
            <img
              src="@/assets/svg/edit.svg"
              class="icon-white"
              alt="edit-icon"
            />
          </button>
        </div>
      </div>

      <div class="my-4 d-flex justify-content-start align-content-center">
        <div class="me-5">
          <FormInput
            v-model:modelValue="profile.first_name"
            :disabled="!editMode"
            :error="profileErrors.first_name"
          >
            <span class="profile-label">First Name</span>
          </FormInput>
        </div>

        <div class="me-5">
          <FormInput
            v-model:modelValue="profile.last_name"
            :disabled="!editMode"
            :error="profileErrors.first_name"
          >
            <span class="profile-label">Last Name</span>
          </FormInput>
        </div>

        <div>
          <BaseInputDisplay :label="'Role'" :value="profile.role" />
        </div>
      </div>

      <div class="my-4 row">
        <div class="col">
          <FormInput
            v-model:modelValue="profile.company_name"
            :disabled="!editMode"
            :error="profileErrors.profile"
          >
            <span class="profile-label">Business Name</span>
          </FormInput>
        </div>

        <div class="col">
          <FormInput
            v-model:modelValue="profile.phone"
            :disabled="!editMode"
            :error="profileErrors.phone"
          >
            <span class="profile-label">Contact Number</span>
          </FormInput>
        </div>

        <div class="col">
          <FormInput
            v-model:modelValue="profile.email"
            :disabled="!editMode"
            :error="profileErrors.email"
            :label="`email`"
          >
            <span class="profile-label">Email</span>
          </FormInput>
        </div>
      </div>

      <div class="my-4">
        <FormInput
          v-model:modelValue="profile.address"
          :disabled="!editMode"
          :error="profileErrors.address"
          :label="`address`"
        >
          <span class="profile-label">Address</span>
        </FormInput>
      </div>
    </div>
    <div class="container mb-5">
      <BaseButton v-if="editMode" class="float-end px-5" @click="updateProfile">
        Save
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseInputDisplay from '@/components/TPD/Base/BaseInputDisplay.vue'
import FormInput from '@/components/TPD/Components/FormInput.vue'

import BaseButton from '@/components/Base/BaseButton.vue'
// import BaseRequiredLabel from '@/components/Base/BaseRequiredLabel.vue'

export default {
  name: 'Profile',
  components: {
    BaseInputDisplay,

    BaseButton,
    // BaseRequiredLabel,
    FormInput,
  },

  data() {
    return {
      pageReady: false,
      profile: null,
      profileErrors: {
        // first_name: '',
        // last_name: '',
        // email: '',
        // business_name: '',
        // role: '',
        // address: '',
        // phone: '',
      },
      editMode: false,
    }
  },

  mounted() {
    if (this.$store.state.profile) {
      this.profile = this.$store.state.profile

      let roles = []
      //map roles
      this.profile.is_lawyer
        ? roles.push('Lawyer')
        : this.profile.is_doctor
        ? roles.push('Doctor')
        : this.profile.is_specialist
        ? roles.push('Specialist')
        : this.profile.is_nurse
        ? roles.push('Nurse')
        : roles.push('Admin')
      // Join roles together
      this.profile.role = roles.join(', ')
      //this api get call will automatically return the detail of the logged in user

      this.pageReady = true
    } else this.pageReady = false
  },

  methods: {
    async updateProfile() {
      let payload = {
        id: this.profile.id,
        payload: this.profile,
      }
      this.profileErrors = {}
      await this.$store
        .dispatch('updateTPDProfile', payload)
        .then((response) => {
          this.$store.state.profile = response.data

          // Trigger toast text
          this.$root.toasts.push({
            status: 'success',
            content: 'Profile updated successfully!',
          })
        })
        .catch((error) => {
          // TODO: Add a better implementation for error handling
          console.log(error.message)
          if (error.response) {
            this.profileErrors = error.response.data
            // Trigger toast text
            this.$root.toasts.push({
              status: 'warning',
              content: 'Please check your inputs!',
            })
          } else if (error.request) {
            console.log(`${error.request}`)
          } else {
            console.log(`${error.message}`)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
#profile_info_section {
  p {
    margin-top: 20px;
    border-bottom: 0.5px solid #45b1aa;
  }

  .profile-label {
    font-size: 0.8em;
    color: #45b1aa;
  }

  .icon-white {
    filter: invert(100%) sepia(100%) saturate(24%) hue-rotate(3deg)
      brightness(107%) contrast(106%);
  }
}
</style>
